<template>
  <section class="hero is-fullheight">
    <ModalSubLineChild
      v-if="activeModalSubLineChild"
      :active="activeModalSubLineChild"
      :idSelect="idSubLineSelect"
      @set-data-add-sub-line="addChildSubLine"
      @close-modal="deactivateModalAddSubLineChild"
    />
    <ModalRemoveLine
      v-if="activeModalRemoveLine"
      :active="activeModalRemoveLine"
      @close-modal="activeModalRemoveLine = false"
    />
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="card card_parent" v-if="modeEdit">
              <div class="card-content">
                <div class="columns is-multiline">
                  <div class="column is-7">
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-8">
                        <label class="label mt-3">Línea</label>
                        <input
                          class="input"
                          type="text"
                          placeholder="Línea"
                          v-model="parentLineName"
                          name="parentLineName"
                          v-validate="'required'"
                          data-vv-as="de la línea"
                        >
                        <span v-show="vverrors.has('parentLineName')" class="help is-danger">{{ vverrors.first('parentLineName') }}</span>
                      </div>

                      <div class="column is-12 mb-6">
                        <label class="label">Sub línea</label>
                        <div class="columns is-multiline is-mobile">
                          <div class="column is-8">
                            <input
                              class="input"
                              type="text"
                              placeholder="Sub línea"
                              v-model="subLineTemp"
                              data-vv-as="de la Sub línea"
                            >
                          </div>
                          <div class="column is-2">
                            <button class="button is_green" @click="addSubLineToArray">
                              +
                            </button>
                          </div>
                        </div>
                        <span class="help is-danger" v-show="hasErrorNotSubLine">La Línea debe tener al menos una Sub línea</span>
                        <span class="help is-danger" v-show="hasErrorNotSubLineChild">La(s) Sub línea(s) debe(n) tener al menos un elemento</span>
                      </div>

                      <template v-for="subLine in subLines">
                        <div class="column is-12" :key="`parent-subline-${subLine.id}`" v-if="subLine.active">
                          <div class="columns is-multiline is-vcentered is-mobile">
                            <!-- sublineas -->
                            <div class="column is-5-desktop is-10-mobile">
                              <p class="label">{{ subLine.name }}</p>
                            </div>
                            <div class="column is-1-desktop is-2-mobile is-flex is-justify-content-center is-align-items-center">
                              <img
                                class="icon_delete"
                                src="@/assets/icon/trash_icon.png"
                                alt="Borrar"
                                @click="removeSubLine(subLine.id)"
                              >
                            </div>
                            <div class="column is-6-desktop is-12-mobile">
                              <p class="emphasis_link" @click="activeModalAddSubLineChild(subLine.id)">
                                Agregar sub línea de producto
                              </p>
                            </div>
                            <!-- sublineas childs -->
                            <template v-if="subLine.childSubLine.length > isEmpty">
                              <template v-for="child in subLine.childSubLine">
                                <div class="column is-12" :key="`child-subline-${child.id}`" v-if="child.active">
                                  <div class="columns is-multiline is-mobile">
                                    <div class="column is-6 pl-6">
                                      <p>{{ child.name }}</p>
                                    </div>
                                    <div class="column is-2">
                                      <img
                                        class="icon_delete_item"
                                        src="@/assets/icon/cancel_icon.png"
                                        alt="Borrar"
                                        @click="removeChildSubLine(subLine.id, child.id)"
                                      >
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <!-- Columna para la imagen -->
                  <div class="column is-3">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <card-upload-file
                          :whitBorder="true"
                          titleCard="Imagen de portada"
                          :previewImage="previewImageCover"
                          :setFile="setFileCover"
                          :modeEdit="modeEdit"
                          validate="ext:jpeg,jpg,png|size:1000"
                          accept=".jpeg,.jpg,.png,"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- Columna para el estatus -->
                  <div class="column is-2">
                    <label class="label mt-3">Estatus</label>
                    <span>
                      <input
                        id="switchStatus"
                        type="checkbox"
                        class="switch is-rounded is-small"
                        :checked="status ? 'checked' : ''"
                        v-model="status"
                        name="switchStatusStatus"
                      >
                      <label for="switchStatus"></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card_parent" v-else>
              <div class="card-content" v-if="!loading">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <div class="columns is-multiline">
                      <div class="column is-3 mb-6">
                        <label class="label mt-3">Nombre</label>
                        <p class="paragraph">{{ parentLineName }}</p>
                      </div>
                      <div class="column is-3 mb-6">
                        <label class="label mt-3">Estatus</label>
                        <p :class="[ status ? 'text_green' : 'text_error' ]">
                          {{ status ? 'Activo' : 'Inactivo' }}
                        </p>
                      </div>
                      <div class="column is-3">
                        <card-upload-file
                          class="card_file"
                          :whitBorder="true"
                          titleCard="Imagen de portada"
                          :previewImage="previewImageCover"
                          :modeEdit="modeEdit"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <p class="line_right">
                      <span>SUB LÍNEAS</span>
                    </p>
                  </div>
                  <div class="column is-4" v-for="subLine in subLines" :key="`subline-text-${subLine.id}`">
                    <label class="label">
                      [{{ subLine.id }}] {{ subLine.name }}
                    </label>
                    <p v-for="child in subLine.childSubLine" :key="`chile-text-${child.id}`">
                      [{{ child.id }}] {{ child.name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import utilities from '@/extras/utilities'

export default {
  name: 'LineMainDetail',
  inject: ['$validator'],
  components: {
    CardUploadFile: () => import('@/components/UI/CardUploadFile.vue'),
    ModalSubLineChild: () => import('@/components/Products/Lines/ModalSubLineChild.vue'),
    ModalRemoveLine: () => import('@/components/Products/Lines/ModalRemoveLine.vue')
  },
  data () {
    return {
      isEmpty: 0,
      currentId: 0,
      previewImageCover: null,
      parentLineName: null,
      subLineTemp: null,
      subLines: [],
      status: false,
      activeModalSubLineChild: false,
      idSubLineSelect: null,
      hasErrorNotSubLine: false,
      hasErrorNotSubLineChild: false,
      activeModalRemoveLine: false,
      loading: false
    }
  },
  props: {
    line: {
      type: Object,
      default: () => {}
    },
    modeEdit: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addSubLineToArray () {
      if (this.subLineTemp) {
        this.hasErrorNotSubLine = false
        this.currentId += 1
        const defaultSubLine = {
          id: this.currentId,
          name: this.subLineTemp,
          active: true,
          isNew: true,
          childSubLine: []
        }
        this.subLines.push(defaultSubLine)
        this.subLineTemp = null
      }
    },
    removeSubLine (subLineId) {
      const { isNew } = this.subLines.find(item => item.id === subLineId)
      const removeIndex = this.subLines.map(item => item.id).indexOf(subLineId)
      // Eliminamos si la sublinea es nueva, lo desactivamos si ya existe en la base de datos
      if (isNew) {
        this.subLines.splice(removeIndex, 1)
      } else {
        this.subLines[removeIndex].active = false
        // Desactivamos los hijxs
        this.subLines.map(item => {
          if (item.id === subLineId) {
            item.childSubLine.map(child => { child.active = false })
          }
        })
      }
    },
    activeModalAddSubLineChild (id) {
      this.idSubLineSelect = id
      this.activeModalSubLineChild = true
    },
    deactivateModalAddSubLineChild () {
      this.idSubLineSelect = null
      this.activeModalSubLineChild = false
    },
    async addChildSubLine ({ sublineId, subProductLine }) {
      this.hasErrorNotSubLineChild = false
      await this.subLines.map(item => {
        // Buscamos la sublinea
        if (item.id === sublineId) {
          let idTemp = 0
          // Validamos que exista el array de childSubLine
          if (item.childSubLine.length > this.isEmpty) {
            // Obtenemos el ultimo id del array existente
            const maxId = item.childSubLine.reduce((prev, current) => {
              if (current.id > prev.id) { return current } else { return prev }
            })
            idTemp = maxId.id
          }
          const newChild = {
            id: idTemp += 1,
            name: subProductLine,
            active: true,
            isNew: true
          }
          item.childSubLine.push(newChild)
        }
      })
      this.deactivateModalAddSubLineChild()
    },
    async removeChildSubLine (parentId, childId) {
      // Index de la sublinea
      const parentIndex = this.subLines.map(item => item.id).indexOf(parentId)
      // Index de la sublinea hijx
      const removeIndex = this.subLines[parentIndex].childSubLine.map(item => item.id).indexOf(childId)
      // Buscamos el elemento a eliminar/desactivar
      const { isNew } = this.subLines.find(item => item.id === parentId).childSubLine.find(child => child.id === childId)
      // Eliminamos si la sublinea hijx es nueva, lo desactivamos si ya existe en la base de datos
      if (isNew) {
        this.subLines[parentIndex].childSubLine.splice(removeIndex, 1)
      } else {
        this.subLines[parentIndex].childSubLine[removeIndex].active = false
      }
    },
    async setFileCover (event) {
      if (event) {
        const [file] = event.target.files
        const imageFile = { key: event.target.name, file }
        // Preview
        if (file) {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImageCover = e.target.result
          }
          this.$emit('set-image', imageFile)
        }
      }
    },
    async isThereOne (arrayToValidate) {
      // Validamos que exista al menos un elemento activo
      const resp = arrayToValidate.filter(item => item.active === true)
      return resp.length !== this.isEmpty
    },
    async validateSubLines () {
      this.hasErrorNotSubLine = false
      this.hasErrorNotSubLineChild = false
      // Validamos que exista al menos una sublinea activa
      if (await this.isThereOne(this.subLines)) {
      // Validamos que exista al menos una sublinea hijx activa
        const subLinesfilled = await Promise.all(this.subLines.map(async child => {
          if (child.active) {
            if (await this.isThereOne(child.childSubLine)) { return true } else { return false }
          }
        }))
        if (subLinesfilled.includes(false)) {
          this.hasErrorNotSubLineChild = true
          return false
        } else {
          return true
        }
      } else {
        this.hasErrorNotSubLine = true
        return false
      }
    },
    async getLocalData () {
      const dataSet = {
        parentLineName: this.parentLineName,
        subLinesList: this.subLines,
        active: this.status
      }
      return dataSet
    },
    async getLastId (myArray) {
      const maxId = myArray.reduce((prev, current) => {
        if (current.id > prev.id) { return current } else { return prev }
      })
      return maxId.id
    },
    async spredDataChild () {
      // Obtencion del ultimo id de las sublineas
      if (utilities.objectValidate(this.line, 'subLines', false) && this.line.subLines.length) {
        this.currentId = await this.getLastId(this.line.subLines)
      }
      // Asignando los valores
      this.parentLineName = this.line.parentLineName
      this.previewImageCover = this.line.coverImage
      this.subLines = this.line.subLines
      this.status = this.line.active
      return true
    }
  },
  async beforeMount () {
    await this.spredDataChild()
  }
}
</script>

<style lang="scss" scoped>
  .card.card_parent {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .card.card_file {
    /deep/ .card-content {
      padding: 5px;
    }
  }

  .button.is_green {
    background-color: #7CBC1E;
    border-color: #7CBC1E;
    color: white;
    font-size: 25px;
    padding-bottom: calc(0.1em - 1px);
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: calc(0.1em - 1px);
    height: 1.6em;
    outline: none;
  }
  .button.is_green:focus:not(:active),
  .button.is_green.is-focused:not(:active) {
    box-shadow: none;
  }

  .icon_delete {
    cursor: pointer;
    width: 22px;
  }
  .icon_delete_item {
    cursor: pointer;
    width: 18px;
  }
  .icon_delete:hover,
  .icon_delete_item:hover {
    filter: invert(41%) sepia(55%) saturate(5359%) hue-rotate(327deg) brightness(100%) contrast(102%);
  }
  .emphasis_link {
    cursor: pointer;
    font-size: 16px;
  }

  .line_right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.17;
    color: #262626;
    span {
      background: #fff;
      margin: 0 15px;
    }
  }
  .line_right:after {
    background: #e8e8e8;
    height: 2px;
    flex: 1;
    content: '';
  }
  .line_right:before {
    background: none;
  }
</style>
